import ApplicationController from '~orchid/js/controllers/application_controller';

export default class extends ApplicationController {



    connect() {
        this.cloudinaryOptions = JSON.parse(this.data.get('options'))

        if ( this.element.querySelector('video.cld-video-player')) {
            window.cloudinary.videoPlayer(
                this.element.querySelector('video.cld-video-player'),
                {
                    cloud_name: this.cloudinaryOptions.cloudName,
                    sourceTypes: ['hls', 'dash', 'webm/vp9', 'mp4/h265', 'mp4']
                }
            );
        }
    }

    openVideoWidget() {
        window.cloudinary.createUploadWidget(
            this.cloudinaryOptions,
            (error, result) => {
                if (!error && result && result.event === "success") {
                    console.log(result.info);
                    const inputDuration = document.querySelector('input[name="chapter[duration]"]');
                    const input = this.element.querySelector('input');
                    input.value = result.info.public_id;
                    const button = this.element.querySelector('button');
                    button.setAttribute('disabled', 'disabled');
                    const feedback = this.element.querySelector('#feedback');
                    feedback.innerHTML = `<div class="alert alert-success" role="alert">File uploaded successfully</div>`
                    const cloudinaryResultInput = this.element.querySelector('input[name=cloudinaryResult]');
                    cloudinaryResultInput.value = JSON.stringify(result.info)

                    if (inputDuration) {
                        inputDuration.value = parseInt(result.info.duration)
                    }
                }
            }).open();
    }
}
